<template>
  <div>
    <p class="mb-3">
      Which of the following species would have the same shape as the
      <stemble-latex content="$\ce{NO3-}$" />
      anion?
    </p>

    <v-form @submit.prevent="submitResponse">
      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question333',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking

      inputs: {
        input1: null,
      },
      options: [
        {text: '$\\ce{SO3^2-}$', value: 'so3twoMinus'},
        {text: '$\\ce{SO3}$', value: 'so3'},
        {text: '$\\ce{NH3}$', value: 'nh3'},
        {text: '$\\ce{ClF3}$', value: 'clf3'},
        {text: '$\\ce{ClO3-}$', value: 'clo3minus'},
      ],
    };
  },
};
</script>
